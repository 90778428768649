<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-4'"
  >
    <div class="px-4 py-1 container-fluid">
      <!-- Aktualna lokalizacja -->
      <breadcrumbs :current-page="currentRouteName" :color="myColor" />

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <ul class="navbar-nav justify-content-end ms-md-auto">
          <!-- Logotyp -->
          <li
            v-if="logo"
            class="nav-item me-3 dropdown mb-2"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              class="p-0 nav-link lh-1 cursor-pointer"
              :class="[myColor]"
              @click="$redirect('Kokpit')"
            >
              <img :src="'data:image/jpeg;base64,' + logo" style="width: 100px !important">
            </a>
          </li>

          <!-- Wiadomości -->
          <li
            v-if="me && me.roles.includes('ROLE_MESSAGES')"
            class="nav-item me-2 dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              class="p-0 nav-link lh-1 cursor-pointer"
              :class="[myColor]"
              @click="$redirect('Wiadomości')"
            >
              <i class="material-symbols-outlined cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">chat_bubble</i>
              <a
                v-if="messages"
                :class="`bg-gradient-${ color } position-absolute bottom-0 mb-4 ms-n2 rounded-circle`"
                style="padding: 5px"
              ></a>
            </a>
          </li>

          <!-- Powiadomienia -->
          <li
            class="nav-item me-2 dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              class="p-0 nav-link lh-1 cursor-pointer"
              :class="[myColor]"
              data-bs-toggle="dropdown"
            >
              <i class="material-symbols-outlined" :class="isDarkMode ? 'text-white' : 'text-dark'">notifications</i>
              <a
                v-if="notification"
                :class="`bg-gradient-${ color } position-absolute bottom-0 mb-4 ms-n2 rounded-circle`"
                style="padding: 5px"
              ></a>
            </a>
            <ul
              class="p-2 dropdown-menu dropdown-menu-end me-sm-n3 shadow overflow-auto"
              style="width: 400px; height: 500px; cursor: default"
              @click="$event.stopPropagation()"
            >
              <li>
                <div class="mx-2 pt-2">
                  <div class="d-flex justify-content-center">
                    <h6 class="text-sm me-auto font-weight-bold" style="cursor: text">
                      Powiadomienia
                    </h6>
                    <span class="text-xs mt-1 ms-auto font-weight-normal cursor-pointer" @click="onChangeIsRead()">
                      Oznacz wszystkie jako przeczytane
                    </span>
                  </div>
                </div>
              </li>
              <hr class="my-1 mx-1">
              <li v-if="notifications.length === 0" class="text-center my-2">
                <div>Brak powiadomień</div>
              </li>
              <li>
                <div
                  v-for="item in notifications"
                  :key="item"
                  class="mx-2 mt-3 py-1 d-flex cursor-pointer"
                  @click="item.event ? patchNotification(item.id, 'event', item.event.id) : item.sale ? patchNotification(item.id, 'sale', item.sale.id) : patchNotification(item.id, null, null)"
                >
                  <div
                    class="icon icon-shape shadow text-center me-3"
                    :class="isDarkMode ? 'bg-gradient-light' : 'bg-gradient-dark'"
                    style="width: 57px !important; border-radius: 50%"
                  >
                    <i class="material-icons opacity-10 pt-1" :class="isDarkMode ? 'text-dark' : 'text-light'">notifications</i>
                  </div>
                  <div class="d-flex flex-column justify-content-center w-100">
                    <h6 class="mb-1 text-sm font-weight-normal">
                      <span :class="item.isRead ? '' : 'font-weight-bold'">{{ item.description }}</span>
                    </h6>
                    <p class="mb-0 text-xs opacity-7">
                      {{ item.createdAt }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </li>

          <!-- Profil -->
          <li
            v-if="me"
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-0'"
          >
            <a
              class="px-0 nav-link font-weight-bold lh-1 cursor-pointer"
              :class="[myColor]"
              data-bs-toggle="dropdown"
            >
              <material-avatar
                :img="$getAvatar(me.fullName, me.avatar)"
                size="xs"
                shadow="sm"
                circular
                alt="user image"
              />
            </a>
            <ul
              class="p-2 dropdown-menu dropdown-menu-end me-sm-n4 shadow"
              style="cursor: default"
              @click="$event.stopPropagation()"
            >
              <li>
                <div class="pt-2 d-flex">
                  <div class="ms-2 d-flex flex-column justify-content-center">
                    <h6 v-if="me" class="text-sm font-weight-bold" style="cursor: text">
                      {{ me.fullName }}
                    </h6>
                  </div>
                </div>
              </li>
              <hr class="m-1">
              <li>
                <a class="dropdown-item border-radius-md cursor-pointer" @click="$redirect('Zarządzanie kontem')">
                  <div class="pt-2 d-flex">
                    <div class="my-auto">
                      <i class="material-symbols-outlined cursor-pointer">
                        account_circle
                      </i>
                    </div>
                    <div class="ms-3 d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal">
                        Zarządzanie kontem
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md cursor-pointer" @click="$redirect('Personalizacja')">
                  <div class="pt-2 d-flex">
                    <div class="my-auto">
                      <i class="material-symbols-outlined cursor-pointer">
                        settings
                      </i>
                    </div>
                    <div class="ms-3 d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal">
                        Personalizacja
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <hr class="m-1">
              <li>
                <a class="dropdown-item border-radius-md cursor-pointer" @click="logout()">
                  <div class="pt-2 d-flex">
                    <div class="my-auto">
                      <i class="material-symbols-outlined cursor-pointer">
                        logout
                      </i>
                    </div>
                    <div class="ms-3 d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal">
                        Wyloguj
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>

          <!-- Menu -->
          <li class="ps-2 ms-1 nav-item d-xl-none d-flex align-items-center">
            <a
              class="p-0 nav-link lh-1 cursor-pointer"
              :class="myColor"
              @click="toggleSidebar"
            >
              <i class="material-icons" :class="isRTL ? 'ms-sm-2' : 'me-sm-0'" :style="isDarkMode ? 'color: white' : 'color: #344767'">
                menu
              </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "@/examples/Breadcrumbs.vue"
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import LoginDataService from "@/services/LoginDataService"
import UserDataService from "@/services/UserDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import NotificationDataService from "@/services/NotificationDataService"
import ConversationDataService from "@/services/ConversationDataService"
import { mapMutations, mapState } from "vuex"

export default {
  name: "Navbar",
  components: {
    MaterialAvatar,
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {}
    },
    myColor: {
      type: String,
      default: "text-body"
    }
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      logo: "", // Logotyp firmy

      notifications: [], // Tablica powiadomien zalogowanego użytkownika
      notification: false, // Status powiadomień
      
      messages: false, // Status wiadomości
    }
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute", "isDarkMode", "color"]),

    currentRouteName() {
      return this.$route.name
    }
  },
  created() {
    if (localStorage.getItem("token")) {
      this.getMe()
    }

    this.minNav
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.timer = setInterval(() => {
        if (this.me) {
          this.getNotifications(this.me.id)
          this.getMessagesStatus(this.me.id)
        }
      }, 5000)
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize()
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        this.me = res.data
        this.getApp()
        this.getNotifications(res.data.id)
        this.getMessagesStatus(res.data.id)
        console.log(this.me)
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca logo firmy
    getApp() {
      AppSettingsDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          if (item.name === "company_logotype") {
            if (item.value) {
              this.logo = item.value
            }
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca powiadomienia obecnie zalogowanego użytkownika
    getNotifications(id) {
      NotificationDataService.getAll(`?recipient.id=${ id }&order[createdAt]=desc&createdAt[before]=${ this.moment().format("YYYY-MM-DD HH:mm") }`)
      .then(res => {
        this.notifications = []
        this.notification = false

        res.data["hydra:member"].forEach(item => {
          this.notifications.push(item)

          if (!item.isDelivered) {
            console.log("Nowe powiadomienie")
            this.sendNotification(item.id, item.description)
          }

          if (!item.isRead) {
            this.notification = true
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca status powiadomienia na przeczytane
    patchNotification(id, type, typeId) {
      NotificationDataService.patch(id,
        {
          isRead: true
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getNotifications(this.me.id)

        if (type === "event") {
          this.$store.state.openedEvent = typeId
          this.$redirect("Kalendarz")
        }
        else if (type === "sale") {
          this.$store.state.openedSale = typeId
          this.$redirect("Sprzedaż")
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    
    // Funkcja zmieniająca status powiadomienia na dostarczone
    patchIsDelivered(id) {
      NotificationDataService.patch(id,
        {
          isDelivered: true
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca nieprzeczytanie wiadomości
    getMessagesStatus(id) {
      ConversationDataService.getAll(`?members.id=${ id }&seen=false`)
      .then(res => {
        this.messages = false
        
        res.data["hydra:member"].forEach(conversation => {
          if (conversation.messages.length !== 0 &&
              conversation.messages[conversation.messages.length - 1].sender.id !== id &&
              conversation.messages[conversation.messages.length - 1].seen === false) {
            this.messages = true
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wyświetlająca powiadomienie push
    sendNotification(id, text) {
      if (!("Notification" in window)) {
        alert("Twoja przeglądarka nie obsługuje powiadomień push");
        return;
      }

      if (!this.me.settings.sendNotifications || !this.me.settings.pushNotifications) {
        console.log("Powiadomienia push są wyłączone");
        return;
      }

      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notificationOptions = {
            body: text,
            silent: !this.me.settings.playSound,
          };

          new Notification("Powiadomienie push", notificationOptions);
          this.patchIsDelivered(id)
        }
      })
    },

    // Funkcja zmieniająca status powiadomienia dla wszystkich powiadomień
    onChangeIsRead() {
      this.notifications.forEach(item => {
        if (!item.isRead) {
          this.patchNotification(item.id)
        }
      })
    },

    // Funkcja wylogowująca użytkownika
    logout() {
      LoginDataService.logout(
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        console.log(res.data)
        localStorage.removeItem("token")
        if (window.location.search.includes('?asom=1')) {
          this.$router.push({ name: "Login", query: { asom: 1 } })
        }
        else {
          this.$router.push({ name: "Login" })
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
.line {
  width: 2px;
  height: 35px;
  background-color: #dee0e6;
}
</style>